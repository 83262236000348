<script>
export default {
  data() {
    return {
      backend_link: process.env.VUE_APP_BACKEND_URL
    };
  },
};
</script>
<template>
  <div>
    <!-- Hero Start -->
    <section class="bg-home bg-light d-flex align-items-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="text-center">
              <div
                class="icon d-flex align-items-center justify-content-center bg-soft-primary rounded-circle mx-auto"
                style="height: 90px; width: 90px"
              >
                <i class="uil uil-thumbs-up align-middle h1 mb-0"></i>
              </div>
              <h1 class="my-4 font-weight-bold">Thank You</h1>
              <p class="text-muted para-desc mx-auto">
                Thank you for signing up to use stubtools. Shortly you will receive an email from
                <a href="mailto:hello@stubtools.com" class="text-primary">hello@stubtools.com</a>
                with information to setup your account. If you have
                any problems you can contact us at
                <a href="mailto:hello@stubtools.com" class="text-primary">hello@stubtools.com</a>
              </p>
              <a :href="backend_link" class="btn btn-soft-primary mt-3">
                Go To Login
              </a>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->
  </div>
</template>